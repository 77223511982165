import schoolApi from "@/api/school";
import store from "@/store";
//import router from "@/router";
//import * as typechecker from 'typechecker';
import moment from 'moment';

import Vue from 'vue'

const IOT_DATA_POLLING_MIN = process.env.VUE_APP_IOT_DATA_POLLING_MIN //ikkim
const IOT_DATA_SUBTRACT_MIN = process.env.VUE_APP_IOT_DATA_LOAD_SUBTRACT_MIN //211008
const VOC_VBAD_START_VAL = process.env.VUE_APP_VOC_VBAD_START_VAL //220121

const state = {
    school: {},
    class: [],
    schoolList: [],
    pollingMin: IOT_DATA_POLLING_MIN, //ikkim for polling time
    iotSubtractMin: IOT_DATA_SUBTRACT_MIN, //211008
    vocVbadStartVal: VOC_VBAD_START_VAL,
    gradeApcCount: null //total gradeApcCount 정보를 저장.
}

// getters
const getters = {
    
    //ikkim for polling time
    pollingMin: (state) => {
        return state.pollingMin;
    },
    //211008
    iotSubtractMin: (state) => {
        return state.iotSubtractMin;
    },
    //220121
    vocVbadStartVal: (state) => {
        return state.vocVbadStartVal;
    },
    
    
     /** 
     * 학교 정보
     * 
     */
    schoolInfo: (state) => {
        //state.school.coverFileNm = require("@/assets/img/bg_school.png");
        return state.school;
    },

    /** 
     * 학급 정보
     * 
     */
    gradeInfo: (state ) => {
        return state.class;
    },

    /** 
     * 기기(공기청정기/공기순환기) 정보
     * 
     */
    mwdDevice : (state)=> {
        if( state.class.length == 0){
            return 0;
        }
        let device = state.class
            .map((val) => {
                return val.mwdDevice;
            });
        return device;    
    },
    /** 
     * 기기(공기청정기/공기순환기) 정보
     * 
     */
    /*
    apcInfo : (state)=>(isOn=null) => {
        if( state.class.length == 0){
            return 0;
        }

        let apc = state.class
            .filter(x => {
                if( isOn != null ){
                    return x.apcInfo.length > 0 && x.mwdDevice && x.mwdDevice.enable==='Y'
                }else{
                    return x.apcInfo.length > 0
                }
            });
        if(apc.length > 0) {
            apc =  apc.map((val) => {
                    return val.apcInfo;
                }).reduce((pre, cur) => {
                    return pre.concat(cur);
                });
        }else {
            return []
        }
        return apc;    
    },
    */

    /** 
     * 기기(공기청정기/공기순환기) 정보, isConnected 추가함.
     * 
     */
     apcInfo : (state)=>(isOn=null, isConnected=null) => {
        if( state.class.length == 0){
            return 0;
        }

        let apc = state.class
            .filter(x => {
                if( isOn != null && isConnected != null){
                    return x.isConnected==='Y' && x.apcInfo.length > 0 && x.mwdDevice && x.mwdDevice.enable==='Y'
                }else if( isOn != null ){
                    return x.apcInfo.length > 0 && x.mwdDevice && x.mwdDevice.enable==='Y'
                }else{
                    return x.apcInfo.length > 0
                }
            });
        if(apc.length > 0) {
            apc =  apc.map((val) => {
                    return val.apcInfo;
                }).reduce((pre, cur) => {
                    return pre.concat(cur);
                });
        }else {
            return []
        }
        return apc;    
    },

    /** 
     * 공기청정기 정보
     * 
     */
    airCleanerInfo: (state, getters) => {
        /*let totalInfo = [];
        totalInfo = totalInfo.concat( getters.classInfo.map(info => {
            return info.apcInfo;
        }));
        console.info(totalInfo)
        */

        if( state.class.length == 0){
            //return {total : 0, on : 0 }
            return Vue.prototype.$apc.deviceInfo(null, null);
        }

        let airCleanerInfo = getters.apcInfo('Y').filter(air => {
            return air.apcType == 0
        });

        //console.log("airCleanerInfo="+JSON.stringify(airCleanerInfo));

        /*
        let onInfo = airCleanerInfo.filter(air => {
            return air.power == 'Y'
        });

        return {total : airCleanerInfo.length, on : onInfo.length }
        */

        return Vue.prototype.$apc.deviceInfo(state.school.type, airCleanerInfo);
    },

    /** 
     * 공기순환기 정보
     * 
     */
    airCirculatorInfo: (state, getters) => {

        if( state.class.length == 0){
            //return {total : 0, on : 0 }
            return Vue.prototype.$apc.deviceInfo(null, null);
        }

        let airCirculatorInfo = getters.apcInfo('Y').filter(apcInfo => {
            return apcInfo.apcType == 1
        });

        //console.log("airCirculatorInfo="+JSON.stringify(airCirculatorInfo));

        /*
        let onInfo = airCleanerInfo.filter(air => {
            return air.power == 'Y'
        });
        */

        /*    
        //circulator
        let circulatorInfo = airCleanerInfo.filter(air => {
            if(Object.keys(air).includes("irCode")) {
                return air.irCode.substring(0,1)!='8' && air.irCode.substring(0,1)!='9';
            }
            return false;
        });
        let onInfoCirculator = circulatorInfo.filter(air => {
            return air.power == 'Y'
        });

        //aircon
        let airconInfo = airCleanerInfo.filter(air => {
            if(Object.keys(air).includes("irCode")) {
                return air.irCode.substring(0,1)=='8';
            }
            return false;
        });
        let onInfoAircon = airconInfo.filter(air => {
            return air.power == 'Y'
        });

        //chair
        let chairInfo = airCleanerInfo.filter(air => {
            if(Object.keys(air).includes("irCode")) {
                return air.irCode.substring(0,1)=='9';
            }
            return false;
        });
        let onInfoChair = chairInfo.filter(air => {
            return air.power == 'Y'
        });

        //console.log("airCleanerInfo:"+JSON.stringify(airCleanerInfo));
        //console.log("total:"+circulatorInfo.length);
        //console.log("on:"+onInfoCirculator.length);
        //console.log("totalAircon:"+airconInfo.length);
        //console.log("onAircon:"+onInfoAircon.length);

        //return {total : airCleanerInfo.length, on : onInfo.length}   
        return {total : circulatorInfo.length, on : onInfoCirculator.length, totalAircon : airconInfo.length, onAircon : onInfoAircon.length, totalChair : chairInfo.length, onChair : onInfoChair.length}   
        */

        return Vue.prototype.$apc.deviceInfo(state.school.type, airCirculatorInfo);
    },

    /** 
     * 공기청정기 정보
     * 
     */
     airCleanerInfo2: (state, getters) => {

        if( state.class.length == 0){
            //return {total : 0, on : 0 }
            return Vue.prototype.$apc.deviceInfo(null, null);
        }

        let airCleanerInfo = getters.apcInfo('Y','Y').filter(air => {
            return air.apcType == 0
        });

        return Vue.prototype.$apc.deviceInfo(state.school.type, airCleanerInfo);
    },

    /** 
     * 공기순환기 정보
     * 
     */
    airCirculatorInfo2: (state, getters) => {

        if( state.class.length == 0){
            //return {total : 0, on : 0 }
            return Vue.prototype.$apc.deviceInfo(null, null);
        }

        let airCirculatorInfo = getters.apcInfo('Y','Y').filter(apcInfo => {
            return apcInfo.apcType == 1
        });


        return Vue.prototype.$apc.deviceInfo(state.school.type, airCirculatorInfo);
    },
    
    schoolList: (state) => {
        let authentication = store.getters["auth/authentication"];
        if(authentication.decoded) {
            if(authentication.decoded.authority == 0 || authentication.decoded.authority == 10) { // admin일 경우
                return state.schoolList;
            }else if(authentication.decoded.authority == 1 || authentication.decoded.authority == 2) { // admin 이외 계정일 경우
                return [state.school]
            }
        }
        return [];
    },

    /** 
     * gradeApcCount
     * 
     */
    gradeApcCount: (state ) => {
        return state.gradeApcCount;
    }
}

const actions = {
   /** 
     * 학교정보를 서버에서 가져온다.
     * 
     * @method getSchoolInfo 
     * @param {String} uuid 학교 고유 UUID, Admin의 경우에 UUID가 존재하지 않음
     */
    async getSchoolInfo({ commit }) {
        let uuid = store.getters["auth/getSchoolUUID"];
        let data = await schoolApi.getSchool(uuid);
        
        //console.log("getSchoolInfo uuid="+uuid+" data="+JSON.stringify(data));

        // store에 저장되어 있는 schoolUUID가 존재하지 않을 경우
        if(data && data.school) {
            let localStorageKey = store.getters["auth/localStorageKey"]; 
            let authentication = store.getters["auth/authentication"];

            if(uuid == null) {
                // 권한이 존재할 경우 
                if(Object.keys(authentication).length != 0) { 
                    authentication.decoded.schoolUUID = data.school.uuid;
                    localStorage.setItem(localStorageKey, JSON.stringify(authentication));
                }
            }

            //school option
            if(data.school.option) {
                data.school.option = Vue.prototype.$common.convertJson(data.school.option);
            }

            //school memo
            if(data.school.memo) {
                let m = Vue.prototype.$common.convertJson(data.school.memo);
                if(!Array.isArray(m)) {
                    let today = moment(new Date());
                    let todayStr = moment(today).format("YYYY/MM/DD HH:mm");
                    let memo = data.school.memo.replaceAll("\r\n", "<br>");
                    data.school.memo = [{date:todayStr, memo:memo, creator:authentication.decoded.name, id:authentication.decoded.id, showAll:true}];
                } else {
                    data.school.memo = m;
                }
            }
            //console.log("memo1="+JSON.stringify(data.school.memo));
        }

        // else if(uuid == null){
        //     router.push("/admin");
        // }

        //console.log("schoooool="+JSON.stringify(data.school));

        //console.log("schoooool2="+JSON.stringify(data.school));

        //console.log("claaaaaaaaaaas="+JSON.stringify(data.class));
        let auth = store.getters["auth/authentication"];
        if(auth && data && data.class && data.class.length > 0) {
            let decoded = auth.decoded;
            //console.log("decoded="+JSON.stringify(decoded));

            //최고관리자, 사이트매니저는 해당사이트의 클래스를 모두 볼수 있다.
            if((decoded && decoded.authority != 0 && decoded.authority != 10) && decoded.option && decoded.option.accessClassList) {
                let outMac = [];
                if(decoded.option.accessClassList.length > 0) {
                    let showClass  = data.class.filter(function(cls){
                        return decoded.option.accessClassList.filter(function(classNo) {
                            if(cls.classType == 0 && classNo == cls.classNo) {
                                if(cls.mwdDevice && cls.mwdDevice.mwdOutMac) {
                                    outMac.push(cls.mwdDevice.mwdOutMac);
                                }
                                return true;
                            }
                            return false;
                            //return cls.classType == 0 && classNo == cls.classNo;
                        }).length != 0
                    });

                    //console.log("showClass="+JSON.stringify(showClass)+" outMac="+JSON.stringify(outMac));
                    let showOutClass = [];
                    if(outMac.length > 0) {
                        //outMac의 중복을 제거한다.
                        let uniqueOutMac = new Set(outMac);
                        uniqueOutMac = [...uniqueOutMac];
                        //console.log("uniqueOutMac="+JSON.stringify(uniqueOutMac));
                        showOutClass  = data.class.filter(function(cls){
                            return uniqueOutMac.filter(function(mwdMac) {
                                return cls.classType == 1 && cls.mwdDevice && cls.mwdDevice.mwdMac == mwdMac;
                            }).length != 0
                        });

                        //console.log("showOutClass="+JSON.stringify(showOutClass));
                    }

                    data.class = showClass;
                    if(showOutClass.length > 0) {
                        data.class.push(...showOutClass);
                    }

                    //console.log("####### class modified #######");
                }
            }
        }
        
        //console.log("showClass="+JSON.stringify(data.class));

        /*
         apcInfo를 체크하여 저장해 놓자.
         apcInfo가 없으면 기기제어 메뉴를 표출하지 않는다.
         전체 apcInfo Count, aircon count, ap count, ac count, 485 count, 등등을 추출하자.
        */
        let gradeApcCount = Vue.prototype.$apc.gradeApcCount(data.class);

        commit('clear', null)
        commit('setSchool', data.school);
        commit('setClass', data.class);

        commit('setGradeApcCount', gradeApcCount);
    },
    /**
     * 학교정보 변경
     * @param {String} uuid 
     */
    async changeUuid({commit}, schooluuid) {
        let uuid = schooluuid;
        let localStorageKey = store.getters["auth/localStorageKey"]; 
        let authentication = store.getters["auth/authentication"];
        // 권한이 존재할 경우 
        if(Object.keys(authentication).length != 0) { 
            authentication.decoded.schoolUUID = uuid;
            // admin일 경우 store에 저장된 자주확인할장소 비워줌
            authentication.decoded.markedClassNo = null;
            localStorage.setItem(localStorageKey, JSON.stringify(authentication));
        }
        let data = await schoolApi.getSchool(uuid);

        //console.log("change school data="+JSON.stringify(data));

        if(data.school && data.school.option) {
            //console.log("change school option="+JSON.stringify(data.school.option));
            data.school.option = Vue.prototype.$common.convertJson(data.school.option);
            /*
            data.school.option = {};//Vue.prototype.$common.convertJson(data.school.option);
            */
            /*
            if(typeof data.school.option !== 'object') {
                data.school.option = JSON.parse(data.school.option);
            }
            */
        }

        if(data.school && data.school.memo) {
            let m = Vue.prototype.$common.convertJson(data.school.memo);
            if(!Array.isArray(m)) {
                let today = moment(new Date());
                let todayStr = moment(today).format("YYYY/MM/DD HH:mm");
                let memo = data.school.memo.replaceAll("\r\n", "<br>");
                data.school.memo = [{date:todayStr, memo:memo, creator:authentication.decoded.name, id:authentication.decoded.id, showAll:true}];
            } else {
                data.school.memo = m;
            }
        }
        //console.log("memo2="+JSON.stringify(data.school.memo));

        /*
         apcInfo를 체크하여 저장해 놓자.
         apcInfo가 없으면 기기제어 메뉴를 표출하지 않는다.
         전체 apcInfo Count, aircon count, ap count, ac count, 485 count, 등등을 추출하자.
        */
        let gradeApcCount = Vue.prototype.$apc.gradeApcCount(data.class);

        commit('clear', null)
        commit('setSchool', data.school);
        commit('setClass', data.class);

        commit('setGradeApcCount', gradeApcCount);
    },
    async changeSchoolList({commit}) {
        let authentication = store.getters["auth/authentication"];
        if(authentication.decoded && (authentication.decoded.authority == 0 || authentication.decoded.authority == 10)) {
            let data = await schoolApi.getSchoolList(null, null, null, {usePage: false});
            commit('setSchoolList', data.list)
        }
    },
    /** 
     * 학급 정보 저장
     * 
     */
    setClass({commit}, schholClass) {
        commit('setClass', schholClass);
    },

    setClassForDashboard({commit}, schholClass) {
        commit('setClassForDashboard', schholClass);
    },

    /** 
     * apcCount저장
     * 
     */
    setGradeApcCount({commit}, gradeApcCount) {
        commit('setGradeApcCount', gradeApcCount);
    },
};

const mutations = {
    /** 
     * 현재 상태 Clear
     * 
     */
    clear(state) {
        state.school = {};
        state.class = [];
    },
    /** 
     * 학교 정보 저장
     * 
     */
    setSchool(state, school) {
        state.school = school
    },
    /** 
     * 학급 정보 저장
     * 
     */
    setClass(state, schholClass) {
        //console.log("setClass");
        if(schholClass != null) {
            schholClass.forEach(elm => {
                if(elm.option != null) {
                    try {
                        elm.option = Vue.prototype.$common.convertJson(elm.option);
                        /*
                        if(typeof elm.option !== 'object') {
                            elm.option = JSON.parse(elm.option);
                        }
                        */
                    } catch (e) {
                        console.error(e); 
                    }
                }
            })
        }
        state.class = schholClass
        //console.log("class="+JSON.stringify(schholClass))
    },
    setClassForDashboard(state, schholClass) {
        //console.log("setClassForDashboard");
        if(schholClass != null) {
            schholClass.forEach(elm => {
                if(elm.option != null) {
                    elm.option = Vue.prototype.$common.convertJson(elm.option);
                    /*
                    if(typeof elm.option !== 'object') {
                        elm.option = JSON.parse(elm.option);
                    }
                    */
                }
            })
        }
        state.class = schholClass
    },
    setSchoolList(state, schoolList) {
        state.schoolList = schoolList
    },

    /** 
     * gradeApcCount 정보 저장
     * 
     */
    setGradeApcCount(state, gradeApcCount) {
        state.gradeApcCount = gradeApcCount
    },
}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}